import React, { Component } from 'react';
import Router from 'next/router';
import Link from 'next/link';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import { bindActionCreators } from 'redux';

import {
  Toolbar,
  Menu,
  Button,
  MenuItem,
} from '@material-ui/core';

import AdminSideNav from './AdminSideNav';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { googleLogout } from '../utils/GoogleLogout';

const logo = '/static/images/Asistensi_logotipo.svg';

const drawerWidth = 240;

const styles = theme => ({
  button: {
    fontSize: '12px',
    fontWeight: '600',
    textTransform: 'capitalize',
    margin: '0 10px 0 0',
    padding: '0',
    '&:hover': {
      background: 'transparent !important',
    }
  },
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
});

class AdminNav extends Component {

  state = {
    anchorEl: null,
    anchorAdmin: null,
    display: true,
    showDialog: false,
    ocult: false,
    isProfile: false,
    anchors: {},
  }

  handleLogOut = () => {
    const {isSuper, isAdmin, isCoordinator, isLeadOperator, actions} = this.props;

    actions.cleanAffiliationAdmin();
    actions.save_token({ token: '' });
    actions.save_titular_email({ email: '' });
    actions.save_user_type({ user_type: 0 });
    googleLogout();
    actions.Logout();

    if (isSuper || isAdmin || isCoordinator || isLeadOperator) {
      Router.push('/admin/login', '/asisten-si');
    } else {
      Router.push('/authentication/signIn','/iniciar-sesion');
    }
    this.setState({ anchorEl: null });
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, anchorAdmin: null, display: false });
  }

  handleClose = () => {
    this.setState({ anchorEl: null, anchorAdmin: null });
  }

  componentDidMount = async () => await this.handleNavbarState();

  handleNavbarState = async () => {
    const pathname = await Router.router ? Router.router.asPath : '/';
    if (pathname === '/perfil' || pathname.indexOf('/perfil/aliado/') !== -1) {
      this.setState({ isProfile: true });
    } else {
      this.setState({ isProfile: false });
    }
    if (
      pathname === '/comenzar-afiliacion' ||
      pathname === '/iniciar-sesion' ||
      pathname === '/registro-cliente'
    ) {
      this.setState({ ocult: true });
    } else {
      this.setState({ ocult: false });
    }
  }

  render() {
    const { classes, logged, full_name, isDoctor } = this.props;

    return (
      <nav className="bg-white shadow-xs font-bold fixed top-0 z-50 w-full">
        <Toolbar className="flex justify-between padding-0 container">
          <AdminSideNav handleLogOut={this.handleLogOut} />
          <div className="justify-between items-center hidden w-full md:flex">
            <Link href="/">
              <img loading="lazy" alt="Logo Asistensi" src={logo} className="w-auto h-10 cursor-pointer" />
            </Link>
            <ul className="flex items-center justify-between">
              <li className={`text-xs text-gray-800 md:pr-5 lg:pr-8 leading-relaxed hover:text-pink-500  ${this.state.ocult === true ? 'no-display' : ''}`}>
                <Link
                  href={isDoctor ? '/admin/consults' : '/admin/subscriptions'}
                  as={isDoctor ? '/admin/consultas' : '/admin/afiliaciones'}
                >
                  Administración
                </Link>
              </li>
              {logged && (
                <li>
                  <Button aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick} className={classes.button}>
                    {full_name}
                    <ArrowDropDownIcon className="color-gray" />
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <MenuItem className="text-gray-800" onClick={this.handleLogOut}>
                      <ExitToAppIcon fontSize="small" className="mr-2 w-5" />
                      <span className="font-bold text-btn">Cerrar Sesión</span>
                    </MenuItem>
                  </Menu>
                </li>
              )}
            </ul>
          </div>
        </Toolbar>
      </nav>
    );
  }
}

AdminNav.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  // user data
  const logged = state.login.get('logged');
  const full_name = state.login.get('full_name');

  const isSuper = state.login.get('id_user') && (parseInt(state.login.get('type')) === 140);
  const isAdmin = state.login.get('id_user') && (parseInt(state.login.get('type')) === 120);
  const isCoordinator = state.login.get('id_user') && (parseInt(state.login.get('type')) === 15);
  const isMarketing = state.login.get('id_user') && (parseInt(state.login.get('type')) === 50);
  const isLeadOperator = state.login.get('id_user') && (parseInt(state.login.get('type')) === 10);
  const isDoctor = state.login.get('id_user') && (parseInt(state.login.get('type')) === 130);
  const isNetworkManager = state.login.get('id_user') && (parseInt(state.login.get('type')) === 115);

  return {
    logged,
    full_name,
    isSuper,
    isAdmin,
    isCoordinator,
    isDoctor,
    isMarketing,
    isLeadOperator,
    isNetworkManager,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AdminNav));
