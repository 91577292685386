import React, { Component } from 'react';
import Link from "next/link";
import { Toolbar } from '@material-ui/core';
import PublicIcon from '@material-ui/icons/Public';
import { actualYear } from '../utils/Date';

const serverUrlImages = process.env.REACT_APP_SERVER_URL_IMAGES;

const apple = `${serverUrlImages}/icons/apple.svg`;
const android = `${serverUrlImages}/icons/google-play.svg`;
const logo = '/static/images/logo.svg';

const facebook = `${serverUrlImages}/icons/facebook.svg`;
const linkedIn = `${serverUrlImages}/icons/linked-in.svg`;
const twitter = `${serverUrlImages}/icons/twitter.svg`;
const instagram = `${serverUrlImages}/icons/instagram.svg`;

const DOMAIN_DO = process.env.REACT_APP_DOMAIN_URL_DO;
const DOMAIN_MX = process.env.REACT_APP_DOMAIN_URL_MX;
const DOMAIN_HN = process.env.REACT_APP_DOMAIN_URL_HN;
const DOMAIN_GLOBAL = process.env.REACT_APP_DOMAIN_URL_GLOBAL;
class Footer extends Component {

  state = {
    ocult: false,
    sitemap: [
      { text: 'Quiénes somos', href: '/about', as: '/quienes-somos', targetBlank: false },
      { text: 'Servicios de salud', href: '/benefits', as: '/beneficios', targetBlank: false },
      { text: 'SOS: médico virtual gratuito', href: '/sos', as: '/sos', targetBlank: false },
      { text: 'Noticias', href: `${process.env.REACT_APP_DOMAIN_URL_GLOBAL}/noticias`, as: false, targetBlank: true },
    ],
    countries: [
      { text: 'República Dominicana', href: DOMAIN_DO },
      { text: 'México', href: DOMAIN_MX },
      { text: 'Honduras', href: DOMAIN_HN },
      { text: <span>Visita <strong>asistensi global</strong></span>, href: DOMAIN_GLOBAL },
    ],
    information: [
      { text: 'Preguntas Frecuentes', href: '/faqs', as: '/faq', showXS: true },
      { text: 'Términos de uso', href: '/termscondition', as: '/terminos-uso', showXS: true },
      { text: 'Políticas de privacidad', href: '/privacypolicy', as: '/politica-privacidad', showXS: true },
      { text: 'Políticas de cookies', href: '/cookiespolicy', as: '/politica-cookies', showXS: true },
      { text: 'Aviso legal', href: '/legalnotice', as: '/legales', showXS: true },
    ]
  }

  componentDidMount = async () => await this.handleFooterState();

  async UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.pathname !== this.props.pathname){
      await this.handleFooterState();
    }
  }

  handleFooterState = async() => {
    const pathname = await this.props.pathname ? this.props.pathname : '/';

    if(
        pathname === '/comenzar-afiliacion' ||
        pathname === '/iniciar-sesion' ||
        pathname === '/registro-cliente' ||
        pathname === '/registro-cliente-opcional' ||
        // Profile
        pathname === '/perfil' ||
        pathname === '/perfil/beneficiarios' ||
        pathname === '/perfil/controles-avanzados' ||
        pathname === '/perfil/gestion-de-pagos' ||
        pathname === '/perfil/gestion-de-renovacion' ||
        pathname === '/perfil/actualizar-renovacion' ||
        pathname === '/perfil/sos' ||
        pathname === '/perfil/sos/consulta-general/motivo' ||
        pathname === '/perfil/sos/consulta-general/antecedentes' ||
        pathname === '/perfil/sos/consulta-general/detalle' ||
        pathname === '/perfil/sos/consulta-general/datos' ||
        pathname === '/perfil/sos/consulta-general/resultados' ||
        pathname === '/perfil/sos/consulta-general/resultadoscontacto' ||
        pathname === '/perfil/sos/consulta-general/finalizado' ||
        pathname === '/perfil/sos/covid-19' ||
        pathname === '/perfil/sos/covid-19/resultados' ||
        // Afilliation
        pathname === '/afiliacion-informacion-pago' ||
        pathname === '/afiliacion-flujo-alterno' ||
        pathname === '/cotizacion' ||
        pathname === '/proceso-finalizado' ||
        // Ally profile
        pathname === '/perfil/aliado/afiliaciones' ||
        pathname === '/perfil/aliado/mis-aliados' ||
        pathname === '/perfil/aliado/mi-balance' ||
        pathname === '/perfil/aliado/recursos-de-ventas' ||
        pathname === '/perfil/aliado/cotizar' ||
        pathname === '/perfil/aliado/premios' ||
        pathname === '/perfil/aliado/mi-bigbroder' ||
        // App routes
        pathname === '/m/terminos-uso' ||
        pathname === '/m/politica-privacidad'
      ) {
      this.setState({ ocult: true });
    } else {
      this.setState({ ocult: false });
    }
  }

  render() {
    return(
      <footer className={`static bg-purple-500 ${this.state.ocult === true ? 'hidden' : ''}`}>
        <Toolbar className="container flex flex-col py-16 padding-x-0">
          <img loading="lazy" src={logo} alt="logo asistensi" className="h-10 w-auto sm:hidden"/>
          <div className="flex w-full sm:pb-14 sm:mt-6 items-start flex-wrap lg:flex-no-wrap">

            <div className="w-full items-center hidden sm:flex flex-col text-base lg:w-2/5 lg:items-start">
              <div className="text-white leading-relaxed">
                Venezuela:
                <a href="tel:+588008363637" rel="noopener noreferrer" className="ml-1 text-pink-500 hidden sm:inline-block">+58 (800) 836 36 37 /</a>
                <a href="tel:+582128221260" rel="noopener noreferrer" className="ml-1 text-pink-500">+58 (212) 822 12 60</a>
              </div>
              <div className="text-white leading-relaxed mt-2">
                Estados Unidos:
                <a href="tel:+13054558811" rel="noopener noreferrer" className="ml-1 text-pink-500">+1 (305) 455 8811</a>
              </div>
              <div className="text-white leading-relaxed mt-2">
                España:
                <a href="tel:+34911238276" rel="noopener noreferrer" className="ml-1 text-pink-500">+34 (911) 238 276</a>
              </div>
              <div className="flex flex-col">
                <div className="flex flex-col sm:flex-row justify-center items-center flex-wrap mt-6">
                  <a className="footer-button w-40 sm:w-48 mt-4 flex justify-center items-center sm:mr-3 text-base" target="_blank" href="https://apps.apple.com/us/app/asistensi/id1494984168?l=es&ls=1">
                    <img loading="lazy" src={apple} alt="icono de apple" className="w-4 mr-3"/>
                    <span className="sm:hidden">iPhone</span>
                    <span className="hidden sm:inline-block">App Store</span>
                  </a>
                  <a className="footer-button w-40 sm:w-48 mt-4 flex justify-center items-center text-base" target="_blank" href="https://play.google.com/store/apps/details?id=com.venemergencia.asistensi">
                    <img loading="lazy" src={android} alt="icono de apple" className="w-4 mr-3"/>
                    <span className="sm:hidden">Android</span>
                    <span className="hidden sm:inline-block">Google Play</span>
                  </a>
                </div>
              </div>
            </div>

              <div className="flex-col text-base hidden text-left sm:flex sm:w-1/3 sm:mt-12 sm:text-center lg:w-1/5 lg:mt-0 lg:text-left">
                <span className="text-pink-500 font-bold font-poppins m-b20 text-lg mb-4">Sobre asistensi</span>
                {
                  this.state.sitemap.map((site, i) => (
                    <Link key={i} href={site.href} as={site.as}>
                      <a
                        className="text-white leading-relaxed text-sm cursor-pointer mt-2"
                        target={site.targetBlank ? "_blank" : ""}
                        rel={site.targetBlank ? "noreferrer" : ""}
                      >
                        {site.text}
                      </a>
                    </Link>
                  ))
                }
              </div>

              <div className="flex-col text-base hidden text-left sm:flex sm:w-1/3 sm:mt-12 sm:text-center lg:w-1/5 lg:mt-0 lg:text-left">
                <span className="text-pink-500 font-bold font-poppins m-b20 text-lg mb-4">En qué países estamos</span>
                {
                  this.state.countries.map((country, i) => (
                    <Link key={i} href={country.href} as={country.as}>
                      <a className="text-white leading-relaxed text-sm cursor-pointer mt-2" target="_blank">
                        {country.text}
                      </a>
                    </Link>
                  ))
                }
              </div>

              <div className="flex flex-col w-full text-base items-center mt-8 sm:w-1/3 sm:mt-12 lg:w-1/5 lg:mt-0 lg:items-start">
                <span className="text-pink-500 font-bold poppins m-b20 hidden text-lg mb-4 sm:inline-block">Información</span>
                {
                  this.state.information.map((inf, i) => (
                    <Link key={i} href={inf.href} as={inf.as}>
                      <a className={`text-white leading-relaxed text-sm cursor-pointer mt-2 ${!inf.showXS && 'hidden sm:inline-block'}`}>
                        {inf.text}
                      </a>
                    </Link>
                  ))
                }
              </div>

              <a href="https://www.asistensi.com/" target="_blank" className="block sm:hidden w-full text-center text-white text-sm mt-8 cursor-pointer">
                <PublicIcon className="text-white mr-2"/>Visita <b>asistensi global</b>
              </a>
          </div>
          
          <div className="flex flex-col sm:hidden mt-4">
            <div className="flex flex-col sm:flex-row justify-center items-center flex-wrap">
              <a className="footer-button w-40 sm:w-48 mt-4 flex justify-center items-center sm:mr-3 text-base" target="_blank" href="https://apps.apple.com/us/app/asistensi/id1494984168?l=es&ls=1">
                <img loading="lazy" src={apple} alt="icono de apple" className="w-4 mr-3"/>
                <span>App Store</span>
              </a>
              <a className="footer-button w-40 sm:w-48 mt-4 flex justify-center items-center text-base" target="_blank" href="https://play.google.com/store/apps/details?id=com.venemergencia.asistensi">
                <img loading="lazy" src={android} alt="icono de apple" className="w-4 mr-3"/>
                <span>Google Play</span>
              </a>
            </div>
          </div>

          <div className="w-full flex flex-col justify-center md:justify-between items-center mt-8 md:border-t border-white border-opacity-25 pt-8 sm:flex-row md:pt-16 md:mt-16 md:flex-row mb-24 md:mb-0">
            <div className="flex sm:order-2 md:order-3 md:items-center">
              <a href="https://www.facebook.com/Asistensi.ve/" target="_blank" rel="noopener noreferrer">
                <img loading="lazy" src={facebook} alt="logo facebook" className="w-5 cursor-pointer mr-5 hover:opacity-50 transition duration-300 ease"/>
              </a>
              <a href="https://www.linkedin.com/company/asistensi" target="_blank" rel="noopener noreferrer">
                <img loading="lazy" src={linkedIn} alt="logo linked in" className="w-5 cursor-pointer mr-5 ml-5 hover:opacity-50 transition duration-300 ease"/>
              </a>
              <a href="https://twitter.com/Asistensi_ve" target="_blank" rel="noopener noreferrer">
                <img loading="lazy" src={twitter} alt="logo twitter" className="w-5 cursor-pointer mr-5 ml-5 hover:opacity-50 transition duration-300 ease"/>
              </a>
              <a href="https://www.instagram.com/asistensi.ve/" target="_blank" rel="noopener noreferrer">
                <img loading="lazy" src={instagram} alt="logo instagram" className="w-5 cursor-pointer ml-5 hover:opacity-50 transition duration-300 ease"/>
              </a>
            </div>
            <img loading="lazy" src={logo} alt="logo asistensi" className="h-10 w-auto hidden order-2 md:inline-block"/>
            <span className="hidden md:block text-white text-xs mt-8 sm:mt-0">©{actualYear} <span className="font-bold">Asistensi</span> Global Insurance, Inc.</span>
          </div>
        </Toolbar>
      </footer>
    );
  }
}

export default Footer;