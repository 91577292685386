import React, {Component} from 'react';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from 'store/actions';

import ReactTelInput from 'react-telephone-input';
import {SpinnerV} from 'components/widgets';
import {TextInput, SelectSimpleOutlined} from 'components/commons';
import Flags from '../../assets/images/flags.png';

import {IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

class ManageLead extends Component {
  state = {
    leadForm: {
      first_name: '',
      message: 'default',
      phone_one: '',
      email: '',
    },
    countryCode: '',
    formErrors: {},
    spinnerScreen: false,
  };

  close = () => {
    this.setState({
      leadForm: {
        first_name: '',
        message: '',
        phone_one: '',
        email: '',
      },
      formErrors: {},
    });
  };

  async componentDidMount() {
    const response = await this.props.actions.getLocation();
    this.setState({ countryCode: (response?.countryCode || 've').toLowerCase() });
  }

  handleInput = (event) => {
    const { name, value } = event.target;
    const { leadForm } = this.state;
    leadForm[name] = value;
    this.setState({ leadForm }, () => {
      this.validateInput(name, value);
    });
  };

  handlePhone = (name, value) => {
    const { leadForm } = this.state;
    leadForm[name] = value;
    this.setState({ leadForm }, () => {
      this.validateInput(name, value);
    });
  };

  validateInput = (name, value) => {
    let { formErrors } = this.state;
    let validate = false;

    switch (name) {
      case 'phone_one':
        validate = /^([0-9()+ -]{10,})$/.test(value);
        (!validate) ? formErrors.phone_one = "Teléfono inválido. Ejemplo: (+58) 416 765-432" : delete formErrors.phone_one;
        break;
      case 'first_name':
        validate = /^([0-9a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,._-]{5,})$/.test(value);
        (!validate) ? formErrors.first_name = "Descripción inválida." : delete formErrors.first_name;
        break;
      case 'message':
        validate = /^([0-9a-zA-ZñÑáéíóúÁÉÍÓÚüÜ !¡,._-]{5,})$/.test(value);
        (!validate) ? formErrors.message = "Horario inválido." : delete formErrors.message;
        break;
      case 'email':
        validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
        (!validate) ? formErrors.email = "Correo electrónico inválido." : delete formErrors.email;
        break;
    }
    this.setState({ formErrors });
  };

  handleSubmit = async () => {
    const {leadForm, formErrors} = this.state;

    Object.keys(leadForm).forEach(k => this.validateInput(k, leadForm[k]));

    if (Object.keys(formErrors).length === 0) {
      this.setState({spinnerScreen: true});
      const response = await this.props.actions.createNewLead({
        ...leadForm,
        origin: 'CALL-REQUEST-HOME-VE',
        marketing_campaign: 'CALL-REQUEST-HOME-VE',
      });
      this.setState({...response}, () => {
        if (response.success) {
          this.props.handleSubmit(response);
        }
      });
    }
  };

  handleClose = () => this.setState({openAlert: false});

  render() {
    const {
      handleClose,
      showSpinner,
    } = this.props;
    const {
      leadForm,
      formErrors,
      spinnerScreen,
      countryCode,
    } = this.state;

    return (
      <div className="w-screen h-screen bg-black bg-opacity-50 fixed top-0 left-0 flex justify-center items-center" style={{ zIndex: '99999999999'}}>
        <style>
          {`
            .telInputFull.otherLabel::before, .telInputErrorFull.otherLabel::before{
              content: '¿A qué número de teléfono te llamamos? *';
              font-weight: normal;
            }
          `}
        </style>
        {spinnerScreen && <SpinnerV/>}
        <span onClick={() => handleClose()} className="absolute right-1 top-0 bg-pink-500 p-3 cursor-pointer hidden sm:inline-block">
          <CloseIcon className="text-white"/>
        </span>
        <div className="bg-white w-full h-full py-5 px-5 overflow-y-scroll sm:px-10 sm:max-w-3xl md:max-w-4xl lg:max-w-4xl sm:h-auto md:max-h-86">
          <div className="w-full flex justify-end sm:hidden ">
            <IconButton onClick={() => handleClose()} style={{ margin: '-1rem -1rem 0 0' }}>
              <CloseIcon className="text-purple-500"/>
            </IconButton>
          </div>
          <div className=" pt-5 flex flex-col sm:flex-row items-center justify-center sm:justify-start h-auto -mb-20 sm:mb-0  sm:h-full ">
            <div className="flex-col mr-0 sm:mr-16 md:mr-28 lg:mr-20 mt-0 sm:-mt-20 flex-1">
              <h1 className="flex text-gray-800 flex-wrap text-purple-500 font-bold text-3xl md:text-4xl lg:text-5xl leading-snug">
                Te llamamos&nbsp;
                <span className="text-pink-500">Gratis</span>
              </h1>
              <p className="mt-4 sm:mt-0 text-base md:text-lg font-normal text-gray-800 leading-relaxed">
              Te asesoramos para escoger el mejor seguro a tu medida y te lo gestionamos sin coste alguno.
              </p>
            </div>

            <div className="w-full  sm:w-1/2 md:w-1/2 text-gray-800 flex-col pb-0 sm:pb-8 pt-10 sm:pt-6 h-full mb-10 sm:mb-0">
              {/* <div className="text-3xl font-poppins font-bold mb-8">Déjanos tus datos para llamarte:</div> */}
              <div className="w-full flex flex-wrap">
                <TextInput
                  name="first_name"
                  label="¿Por quién preguntamos?"
                  placeholder="Nombre"
                  errors={formErrors.first_name}
                  helperText={formErrors.first_name}
                  value={leadForm.first_name}
                  addClass="wInputFull"
                  onChange={this.handleInput}
                />

                <TextInput
                  name="email"
                  label="Correo electrónico"
                  placeholder="Correo electrónico"
                  errors={formErrors.email}
                  helperText={formErrors.email}
                  value={leadForm.email}
                  addClass="wInputFull"
                  onChange={this.handleInput}
                />

                {countryCode &&
                  <ReactTelInput
                    required={true}
                    onChange={(telNumber) => this.handlePhone('phone_one', telNumber)}
                    value={leadForm.phone_one}
                    defaultCountry={countryCode}
                    flagsImagePath={Flags}
                    className={
                      (leadForm.phone_one && !formErrors?.phone_one)
                        ? 'telInputFull otherLabel'
                        : (((!leadForm.phone_one && formErrors?.phone_one) || formErrors?.phone_one) ? 'telInputErrorFull otherLabel' : 'telInputFull otherLabel')
                    }
                  />
                }

                <SelectSimpleOutlined
                  name="message"
                  label="¿En qué horario te viene bien?"
                  value={leadForm.message}
                  placeholder="Selecciona el horario (de Lunes a Viernes)"
                  listItems={[
                    {
                      value: '9am - 12m',
                      name: '9am - 12m',
                    },
                    {
                      value: '12m - 3pm',
                      name: '12m - 3pm',
                    },
                    {
                      value: '3pm - 6pm',
                      name: '3pm - 6pm',
                    },
                    {
                      value: '6pm - 9pm',
                      name: '6pm - 9pm',
                    },
                    {
                      value: '¡Lo antes posible!',
                      name: '¡Lo antes posible!',
                    },
                  ]}
                  addClass={`wInputFull ${leadForm.message === 'default' && 'default'}`}
                  errors={formErrors.message}
                  helperText={formErrors.message}
                  onChange={this.handleInput}
                  required={true}
                />
              </div>
              <div className="flex w-full mt-6 justify-center">
              <button
                className="primary-button min-w-full sm:min-w-0"
                onClick={this.handleSubmit}
                disabled={showSpinner}
              >
                Enviar
              </button>
              </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default (connect(null, mapDispatchToProps)(ManageLead));
