import React, { Component } from 'react';
import Link from 'next/link';
import Router from 'next/router';
import { Toolbar } from '@material-ui/core';

import Sidenav from './Sidenav';
import * as actions from '../../../store/actions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {SnackBar} from 'components/widgets';
import MegaMenu from './MegaMenu';

const logo = '/static/images/Asistensi_logotipo.svg';

class Navbar extends Component {

  state = {
    anchorEl: null,
    display: true,
    ocult: false,
    anchors: {},
    showLead: false,
    hideNumbers: false,
    openAlert: false,
    messageAlert: '',
  }

  handleClose = () => {
    this.setState({ anchorEl: null, });
  }

  showDisplay = () => {
    this.setState({ display: true, anchorEl: null });
  };

  componentDidMount = async () => await this.handleNavbarState();

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pathname !== this.props.pathname) {
      await this.handleNavbarState();
    }
  }

  handleNavbarState = async () => {
    const pathname = await this.props.pathname ? this.props.pathname : '/';
    if (
      pathname === '/comenzar-afiliacion' ||
      pathname === '/iniciar-sesion' ||
      pathname === '/registro-cliente' ||
      pathname === '/registro-cliente-opcional'
    ) {
      this.setState({ ocult: true });
    } else {
      this.setState({ ocult: false });
    }

    const {query} = Router?.router;
    if (query?.cjevent) this.setState({ hideNumbers: true });
    else this.setState({ hideNumbers: false });
  }

  handleSubmitLead = (response) => {
    document.body.classList.remove('modal-open');
    this.setState({...response, showLead: false});
  };

  handleCloseAlert = () => this.setState({openAlert: false});

  showDialog = () => {
    document.body.classList.add('modal-open');
    this.setState({showLead: true})
  }

  closeDialog = () => {
    document.body.classList.remove('modal-open');
    this.setState({showLead: false})
  }

  render() {
    const { display, showLead, messageAlert, openAlert } = this.state;

    const menu = [
      { text: 'Inicio', url: '/' },
      { text: 'Productos', url: '/plans', as: '/planes', prefetch: false, targetBlank: false },
      { text: 'Servicios de salud', url: '/benefits', as: '/beneficios', prefetch: false, targetBlank: false },
      { text: 'Quiénes somos', url: '/about', as: '/quienes-somos', prefetch: false, targetBlank: false },
      { text: 'Preguntas frecuentes', url: '/faqs', as: '/faq', prefetch: false, targetBlank: false },
      { text: 'Contacto', url: '/contact', as: '/contacto', prefetch: false, targetBlank: false },
      { text: 'Noticias', url: `${process.env.REACT_APP_DOMAIN_URL_GLOBAL}/noticias`, as: false, prefetch: false, targetBlank: true },
      { text: 'SOS', url: '/sos', as: '/sos', prefetch: false, targetBlank: false },
    ];

    const navigation = menu.filter(lnk => lnk.text !== 'SOS').map((link, i) => {
      return (
        <React.Fragment key={i}>
          <style>
            {`
              @media only screen and (min-width: 1280px) and (max-width: 1380px) {
                .navbar-selector {
                  font-size: 0.75rem !important;
                }
              }
              @media only screen and (min-width: 960px) and (max-width: 1120px) {
                .navbar-selector {
                  font-size: 0.6rem !important;
                  padding-right: .8rem !important;
                }
              }
            `}
          </style>
          <li key={i} className={`navbar-selector text-small lg:text-xs text-gray-750 md:pr-5 lg:pr-8 leading-relaxed hover:text-pink-500 ${this.state.ocult === true ? 'hidden' : 'inline-block'}`}>
            <Link href={link.url} as={link.as} prefetch={link.prefetch} passHref>
              <a
                className={`
                  ${link.url === '/' ? 'ml-4' : ''}
                  ${link.url === '/sos' ? 'text-pink-500' : ''}
                  ${Router.router && Router.router.pathname && Router.router.pathname === link.url ? 'text-pink-500' : ''}
                `}
                target={link.targetBlank ? "_blank" : ""}
                rel={link.targetBlank ? "noreferrer" : ""}
              >
                {link.text}
              </a>
            </Link>
          </li>
        </React.Fragment>
      )
    });

    return (
      <nav className="bg-white shadow-xs font-bold fixed top-0 z-50 w-full flex flex-col">
        <div className="order-2 md:order-first">
          <MegaMenu
            hideNumbers={this.state.hideNumbers}
            showDialog={this.showDialog}
            closeDialog={this.closeDialog}
            showLead={showLead}
            handleSubmitLead={this.handleSubmitLead}
          />
        </div>
        <Toolbar className="flex justify-between px-5 md:px-20">
          <Sidenav
            navigation={menu}
            display={display}
            goToAffiliation={this.goToAffiliation}
          />
          <div className="justify-between items-center hidden w-full md:flex">
            <Link href="/">
              <img loading="lazy" alt="Logo Asistensi" src={logo} className="w-auto h-10 cursor-pointer" />
            </Link>
            <ul className="flex items-center justify-between">
              {navigation}
              {!this.props.logged && this.props.user_type !== 1 && !this.props.token && (
                <button
                  className={`${Router?.router?.pathname === '/sos' ? 'bg-pink-500 text-white' : 'text-pink-500'} md:hidden lg:inline-block hover:bg-pink-500 hover:text-white cursor-pointer border-pink-500 border rounded-lg font-poppins uppercase font-bold px-4 py-1 text-small lg:text-xs flex justify-center items-center mr-5`}
                  onClick={() => {
                    this.props.actions.setMembershipOrigin('sos');
                    Router.router.push(
                      {
                        pathname: '/sos',
                        query: { origin: 'sos', type: 'sos-home' }
                      },
                      '/sos'
                    );
                  }}
                >
                  SOS
                </button>
              )}
              {!this.props.logged && this.props.user_type !== 1 && !this.props.token && (
                <Link href="/authentication/signUp" as="/registro-cliente">
                  <a
                    onClick={() => this.props.actions.setMembershipOrigin('join')}
                    className="text-small lg:text-xs secondary-button-nav flex md:mr-2 lg:mr-4 justify-center items-center min-w-28"
                  >
                    Suscribirse
                  </a>
                </Link>
              )}
              {!this.props.logged && this.props.user_type !== 1 && !this.props.token && (
                <Link href="/authentication/signIn" as="/iniciar-sesion">
                  <a className="text-small lg:text-xs white-button-nav flex justify-center items-center text-center">
                    Iniciar sesión
                  </a>
                </Link>
              )}
            </ul>
          </div>
        </Toolbar>
        <SnackBar openAlert={openAlert} messageAlert={messageAlert} handleClose={this.handleCloseAlert}/>
      </nav>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default (connect(null, mapDispatchToProps)(Navbar));
